<template>
  <div v-if="permission.access">
    <div class="row">
      <div class="col-md-8">
        <b-card title="User List">
          <b-card-body>
            <b-row class="justify-content-between">
              <b-col
                cols="12"
                md="2"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label class="mr-1">Show</label>
                <Select
                  @on-change="paginateChange"
                  v-model="search.paginate"
                  :clearable="false"
                >
                  <Option :value="10"> 10 </Option>
                  <Option :value="30"> 30 </Option>
                  <Option :value="50"> 50 </Option>
                  <Option :value="80"> 80 </Option>
                  <Option :value="100"> 100 </Option>
                </Select>
              </b-col>
              <b-col cols="12" md="4">
                <Input
                  v-model="search.search_data"
                  class="d-inline-block mr-1"
                  placeholder="Search....."
                  type="text"
                />
              </b-col>
            </b-row>
          </b-card-body>
          <b-card-text>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th class="text-center">Sl</th>
                    <th>Name</th>
                    <th class="text-center">Phone</th>
                    <th class="text-center">Role</th>
                    <th class="text-center">Created at</th>
                    <th
                      class="text-center"
                      v-if="permission.edit || permission.delete"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(user, index) in users.data"
                    :key="index"
                    v-if="users.data.length"
                  >
                    <td class="align-middle text-center">
                      {{ users.meta.from + index }}
                    </td>
                    <td>
                      <b>{{ user.name }}</b>
                      <p class="mb-0"><b>Email:</b> {{ user.email }}</p>
                      <span
                        class="badge mt-0"
                        :class="
                          user.status == 'Active'
                            ? 'badge-primary'
                            : 'badge-secondary'
                        "
                      >
                        {{ user.status }}
                      </span>
                    </td>

                    <td class="text-center">{{ user.phone }}</td>
                    <td class="text-center">{{ user.role }}</td>
                    <td class="text-center">
                      {{ user.created_at }}
                    </td>
                    <td
                      class="align-middle text-center"
                      v-if="permission.edit || permission.delete"
                    >
                      <button
                        v-if="permission.edit"
                        class="btn btn-primary btn-sm"
                        @click="Edit(user.id)"
                      >
                        <feather-icon icon="EditIcon" size="16" />
                      </button>

                      <button
                        v-if="permission.delete"
                        class="btn btn-danger btn-sm ml-1"
                        @click="Delete(user.id)"
                      >
                        <feather-icon icon="TrashIcon" size="16" />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-card-body>
              <div class="d-flex justify-content-between align-items-center">
                <span class="text-muted"
                  >Showing {{ users.meta.from }} to {{ users.meta.to }} of
                  {{ users.meta.total }} entries</span
                >
                <pagination
                  :data="users"
                  @pagination-change-page="getResults"
                  align="right"
                  :show-disabled="true"
                  :limit="5"
                ></pagination>
              </div>
            </b-card-body>
          </b-card-text>
        </b-card>
      </div>
      <div class="col-md-4">
        <b-card :title="(!form.id ? 'Create' : 'Update') + ' User'">
          <b-form @submit.prevent="!form.id ? Create() : Update()">
            <div class="form-group">
              <label> Role </label>
              <Select v-model="form.role_id">
                <Option :value="1"> Admin </Option>
              </Select>
              <has-error :form="form" field="roleId"></has-error>
            </div>
            <div class="form-group">
              <label> Name </label>
              <Input
                v-model="form.name"
                placeholder="Enter candidate name"
                style="width: 100%"
                type="text"
              />
              <has-error :form="form" field="name"></has-error>
            </div>

            <div class="form-group">
              <label> Email </label>
              <Input
                v-model="form.email"
                placeholder="Enter candidate email"
                style="width: 100%"
                type="text"
              />
              <has-error :form="form" field="email"></has-error>
            </div>
            <div class="form-group">
              <label> Phone </label>
              <Input
                v-model="form.phone"
                placeholder="Enter candidate phone number"
                style="width: 100%"
                type="text"
              />
              <has-error :form="form" field="phone"></has-error>
            </div>
            <div class="form-group">
              <label> Password </label>
              <Input
                v-model="form.password"
                placeholder="Enter candidate password"
                style="width: 100%"
                type="text"
              />
              <has-error :form="form" field="password"></has-error>
            </div>
            <div class="d-flex align-items-center justify-content-between mt-2">
              <div>
                <b-form-checkbox
                  name="status"
                  style="margin-top: 5px"
                  v-model="form.status"
                  switch
                  inline
                />
              </div>
              <div>
                <b-button
                  variant="outline-primary"
                  type="button"
                  @click="Clear"
                  class="mx-1"
                >
                  Clear
                </b-button>
                <b-button variant="primary" type="submit" :disabled="form.busy">
                  {{ form.id ? "Update" : "Create" }}
                </b-button>
              </div>
            </div>
          </b-form>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BCardBody,
} from "bootstrap-vue";
import { HasError, AlertError } from "vform/src/components/bootstrap5";
import _ from "lodash";

export default {
  data() {
    return {
      form: new Form({
        id: null,
        role_id: null,
        name: null,
        phone: null,
        email: null,
        password: null,
        status: true,
      }),
      search: {
        search_data: "",
        status: "",
        paginate: 10,
        page: 1,
      },
      users: {},
    };
  },
  components: {
    BCardBody,
    BCard,
    BMedia,
    BFormFile,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BMediaAside,
    BMediaBody,
    HasError,
    AlertError,
  },
  mounted() {
    this.getResults();
  },
  methods: {
    getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      const queryParams = this.buildQueryParamString(this.search);
      axios
        .get(`/app/user${queryParams}`)
        .then((res) => {
          this.users = res.data;
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },

    Clear() {
      this.form.id = null;
      this.form.roleId = null;
      this.form.name = null;
      this.form.email = null;
      this.form.phone = null;
      this.form.password = null;
      this.form.status = true;
    },

    Create() {
      this.form
        .post("/app/user")
        .then((res) => {
          this.getResults();
          this.s(res.data.message);
          this.Clear();
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },
    Update() {
      this.form
        .put("/app/user/" + this.form.id)
        .then((res) => {
          this.getResults();
          this.Clear();
          this.s(res.data.message);
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },

    Edit(id) {
      axios
        .get("/app/user/" + id)
        .then((res) => {
          this.form.id = res.data.user.id;
          this.form.role_id = res.data.user.role_id;
          this.form.name = res.data.user.name;
          this.form.email = res.data.user.email;
          this.form.phone = res.data.user.phone;
          this.form.status = res.data.user.status == 1 ? true : false;
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },

    Delete(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/app/user/" + id)
            .then((res) => {
              this.getResults();
              this.s(res.data.message);
              if (this.users.data.length == 1) {
                this.search.page = 1;
              }
            })
            .catch((e) => {
              this.e(e.response.data.message);
            });
        }
      });
    },
    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.getResults();
    }, 1000),
    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },
  },
  computed: {
    permission() {
      return this.$store.getters.getPermissions.user;
    },
  },
  watch: {
    "search.search_data": function () {
      this.SearchData();
    },
    "search.paginate": function () {
      this.paginateChange();
    },
  },
};
</script>
